import {DETAIL_TELEGRAM,CLEAR_MODAL} from "../actions/type";

const initialState = ''

const detailTelegramReducer = (message = initialState,action) =>{

    const {type,payload} = action

    switch(type){
        case DETAIL_TELEGRAM:
            return payload
        case CLEAR_MODAL:
            return payload
        default:
            return message
    }
}
export default detailTelegramReducer


