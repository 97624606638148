import axios from "axios";
import {GET_PROGRAM,GET_ANSWER,GET_HEADER,GET_DETAILS,MAIN_TELEGRAM,DETAIL_TELEGRAM,CLEAR_STATE,GET_VIDEO,GET_FOOTER,GET_LINKS,CLEAR_MODAL} from "./type";
import {dataProgram,dataAnswer,dataHeader,dataTelegram,detailTelegram,dataVideo,dataFooter,dataLinks} from "../../config";


export const getDetoxLinksAPI = () =>{
    return async(dispatch) =>{
        const res = await axios.get(dataLinks)
        dispatch({
            type:GET_LINKS,
            payload:res.data[0]
        })
    }
}

export const getDetoxFooterAPI = () =>{
    return async(dispatch) =>{
        const res = await axios.get(dataFooter)
        dispatch({
            type:GET_FOOTER,
            payload:res.data[0]
        })
    }
}

export const getDetoxProgramAPI = (setLoading) => {
    return async (dispatch) => {
        const res = await axios.get(dataProgram)
        dispatch({
            type: GET_PROGRAM,
            payload: res.data
        })
        setLoading(false)
    }
}

export const getDetailsAPI = (id,setLoading) => {
    return async (dispatch) => {
        const res = await axios.get(dataProgram+id)
        dispatch({
            type: GET_DETAILS,
            payload: res.data
        })
        setLoading(false)
    }
}




export const getDetoxAnswerAPI = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_ANSWER,
            payload: dataAnswer
        })
    }
}

export const getDetoxVideoAPI = (setLoading) => {
    return async (dispatch) => {
        const res = await axios.get(dataVideo)
        dispatch({
            type: GET_VIDEO,
            payload: res.data
        })
        setLoading(false)
    }
}

export const getDetoxHeaderAPI = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_HEADER,
            payload: dataHeader
        })
    }
}



export const sendTelegramMessage = (data) =>{
    return async(dispatch) =>{
        try{
            const response = await axios.get(dataTelegram + data)
            dispatch({
                type:MAIN_TELEGRAM,
                payload:response.status
            })
        }catch (error) {
            dispatch({
                type:MAIN_TELEGRAM,
                payload:error
            })
        }
    }
}

export const detailTelegramMessage = (data) =>{
    return async(dispatch) =>{
        try{
            const response = await axios.get(detailTelegram + data)
            dispatch({
                type:DETAIL_TELEGRAM,
                payload:response.status
            })
        }catch (error) {
            dispatch({
                type:DETAIL_TELEGRAM,
                payload:error
            })
        }
    }
}

export const clear = () =>{
    return async(dispatch) =>{
        dispatch({
            type:CLEAR_STATE,
            payload:''
        })
    }
}

export const clearModal = () =>{
    return async(dispatch) =>{
        dispatch({
            type:CLEAR_MODAL,
            payload:''
        })
    }
}
