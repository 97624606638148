import {GET_DETAILS,CLEAR_STATE} from "../actions/type";

const initialState = []

const detailsReducer = (details = initialState,action) =>{

    const {type,payload} = action

    switch(type){
        case GET_DETAILS:
            return payload
        case CLEAR_STATE:
            return payload
        default:
            return details
    }
}
export default detailsReducer