import React from 'react';
import Main from "./Main";
import {SkeletonTheme} from "react-loading-skeleton";

const App = () => {
    return (
    <SkeletonTheme baseColor="#313131" highlightColor="#525252">
        <Main/>
    </SkeletonTheme>
    )
};

export default App;

