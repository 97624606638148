import {combineReducers} from "redux"
import detoxProgram from "./reducers/detoxProgramReducer"
import detoxAnswer from './reducers/detoxAnswerReducer'
import detoxHeader from './reducers/detoxHeaderReducer'
import details from './reducers/detailsReducer'
import mainTelegram from './reducers/detoxTelegramReducer'
import detailTelegram from './reducers/detailTelegramReducer'
import detoxVideo from './reducers/detoxVideoReducer'
import detoxFooter from './reducers/detoxFooterReducer'
import detoxLinks from './reducers/detoxLinksReducer'


const rootReducer = combineReducers({
    detoxProgram,
    detoxAnswer,
    detoxHeader,
    details,
    mainTelegram,
    detailTelegram,
    detoxVideo,
    detoxFooter,
    detoxLinks
})
export default rootReducer