import React from 'react';
import Header from "../../components/Header/Header";
import Cover from "../../components/Cover/Cover";
import Advantage from "../../components/Advantage/Advantage";
import DetoxProgram from "../../components/DetoxProgram/DetoxProgram";
import Answer from "../../components/Answer/Answer";
import Consultation from "../../components/Consultation/Consultation";
import Footer from "../../components/Footer/Footer";
import Feedback from "../../components/Feedback/Feedback";

const MainPage = () => {
    return (
        <>
            <Header/>
            <Cover/>
            <Advantage/>
            <DetoxProgram/>
            <Consultation/>
            <Feedback/>
            <Answer/>
            <Footer/>
        </>
    );
};

export default MainPage;