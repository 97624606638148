import React, {useState, useEffect,useCallback} from 'react';
import {Swiper, SwiperSlide } from "swiper/react";
import {FreeMode, Navigation, Thumbs} from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


const DetailsSlider = ({details}) => {

    const [swiper, setSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const slideTo = useCallback(() =>  { if (!swiper || swiper.destroyed) { return; } swiper.slideTo(0) },[swiper]);

    useEffect(()=>{
        if(details){
           slideTo()
        }
    },[details,slideTo])



    return (
        <>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                onSwiper={setSwiper}
                thumbs={{swiper: thumbsSwiper}}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {details.product_images && details.product_images.map((el, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <img className={'details__image'} src={el.img} alt='details'/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper3"
            >
                {details.product_images && details.product_images.map((el, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <img className={'details__small'} src={el.img} alt='details'/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    );
};

export default DetailsSlider;