import React,{useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const Progress = () => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () =>{
            document.body.style.overflow = 'unset'
        }
    }, [])

    return (
        <div style={{width:'100%',height:'100vh',display:'flex', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress color="success" />
        </div>
    );
};

export default Progress;