import React, {useEffect, useState} from 'react';
import './Details.css';
import {useDispatch, useSelector} from "react-redux";
import {clear, getDetailsAPI} from "../../redux/actions/dal";
import TelegramForm from "../TelegramForm/TelegramForm";
import navigateBtn from '../../assets/images/navigateMain.svg'
import {useNavigate, useParams} from 'react-router-dom'
import Confirm from "../Confirm/Confirm";
import DetailsSlider from "./DetailsSlider/DetailsSlider";
import Progress from "../Progress/Progress";


const Details = () => {

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {details} = useSelector(state => state)
    const {detailTelegram} = useSelector(state => state)
    const [state, setState] = useState(false)
    const [loading, setLoading] = useState(true)


    const navigateToMain = () => {
        navigate('/', {state: 'program'})
    }

    const navigateToAnswer = () => {
        navigate('/', {state: 'answer'})
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getDetailsAPI(params.id, setLoading))
        return () => {
            dispatch(clear())
        }
    }, [dispatch, params])


    useEffect(() => {
        if (detailTelegram === 200) {
            setState(true)
        }
    }, [detailTelegram, dispatch])


    return (
        <>
            {!loading ?
                <section id={'details'}>
                    <img
                        onClick={navigateToMain}
                        className='details__navigate'
                        src={navigateBtn}
                        alt="icon"
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="details__box">
                                    <DetailsSlider
                                        details={details}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="details__box-2">
                                    <p className="details__title">{details?.title}</p>

                                    {details?.price
                                        ? <p className="details__price">{(+details?.price)?.toFixed(0)} сом</p>
                                        : null
                                    }
                                    <TelegramForm
                                        title={details?.title}
                                        detailTelegram={detailTelegram}
                                    />

                                    <p className="details__description">{details?.description}</p>
                                    <div>
                                        <p className="details__listTitle">{details?.program_title1}</p>
                                        <ul>
                                            {details.program_list1 && details.program_list1.map((el, index) => {
                                                return (
                                                    <li key={index}><span>{el}</span></li>
                                                )
                                            })}
                                        </ul>
                                        {details?.program_title2
                                            ? <p className="details__listTitle">{details?.program_title2}</p>
                                            : null
                                        }

                                        {details.program_list2?.length > 0
                                            ? <ul>
                                                {details.program_list2 && details.program_list2.map((el, index) => {
                                                    return (
                                                        <li key={index}><span>{el}</span></li>
                                                    )
                                                })}
                                            </ul>
                                            : null
                                        }

                                    </div>
                                    {
                                        details?.text1
                                            ? <p className="details__text">{details?.text1}</p>
                                            : null
                                    }
                                    {
                                        details?.text2
                                            ? <p className="details__text">{details?.text2}</p>
                                            : null
                                    }
                                    {
                                        details?.text3
                                            ? <p className="details__text">{details?.text3}</p>
                                            : null
                                    }
                                    <p className={"details__answer"} onClick={navigateToAnswer}>Более подробно читайте
                                        здесь...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {state ? <Confirm setState={setState}/> : null}
                </section>
                : <Progress/>}
        </>


    );
};

export default Details;