import React, {useEffect} from 'react';
import logo from '../../../assets/images/logoSvg.svg';
import instagram from '../../../assets/images/instagran-icon.svg'
import whatsapp from '../../../assets/images/whatsapp-icon.svg'
import telegram from '../../../assets/images/telegram-icon.svg'
import {Link} from "react-scroll";
import {useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";


const HeaderUI = (props) => {

    const location = useLocation()

    const {detoxProgram} = useSelector(state => state)
    const {detoxVideo} = useSelector(state => state)

    const scrollToAnswer = () => {
        if(detoxProgram && detoxVideo){
            let scrollDiv = document.querySelector("#answer").offsetTop;
            window.scrollTo({top: scrollDiv, behavior: 'smooth'});
        }
    }

    const scrollToProgram = () => {
        let scrollDiv = document.querySelector("#detoxProgram").offsetTop;
        window.scrollTo({top: scrollDiv, behavior: 'smooth'});
    }

    useEffect(() => {
        props.isActive ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset'
    }, [props])

    useEffect(() => {
        if (location.state === 'answer') {
            setTimeout(scrollToAnswer, 0)
        }
        if (location.state === 'program') {
            setTimeout(scrollToProgram, 0)
        }
        window.history.replaceState({}, document.title)

    }, [location])

    return (
        <header>
            <div className="container">
                <div className="header__nav">
                    <Link to={'cover'} smooth={true}><img className={'header__logo'} src={logo} alt=""/></Link>

                    <ul className={`nav ${props.isActive ? 'show-nav' : ''}`}>
                        {props.isActive
                            ? <img className={'header__logo header__logo-disappear'} src={logo} alt=""/> : null
                        }
                        {props.detoxHeader.navData && props.detoxHeader.navData.map(el => {
                            return Object.entries(el).map((el, index) => {
                                return (
                                    <li
                                        key={index}>
                                        <Link
                                            onClick={props.isActive ? props.handleToggle : null}
                                            to={el[0]}
                                            smooth={true}
                                        >{el[1]}</Link></li>
                                )
                            })
                        })}

                        {props.isActive
                            ? <div className={'header__tel'}>
                                <p>{props.detoxLinks?.number1}</p>
                                <p>{props.detoxLinks?.number2}</p>
                            </div>
                            : null
                        }

                        {props.isActive
                            ? <div className={'headers__icons'}>
                                <a target="_blank" rel={'noreferrer'}
                                   href={`https://t.me/${props.detoxLinks?.telegram?.split(' ').join('')}`}><img
                                    src={telegram} alt="icon"/></a>
                                <a target="_blank" rel={'noreferrer'}
                                   href={`https://wa.me/${props.detoxLinks?.whatsapp?.split(' ').join('')}`}><img
                                    src={whatsapp} alt="icon"/></a>
                                <a target="_blank" rel={'noreferrer'} href={props.detoxLinks?.instagram}><img
                                    src={instagram} alt="icon"/></a>
                            </div>
                            : null
                        }
                        <Link
                            to={'consultation'}
                            onClick={props.isActive ? props.handleToggle : null}
                            smooth={true}>
                            <button>{props.detoxHeader.consultation}</button>
                        </Link>
                    </ul>

                    <div onClick={props.handleToggle}
                         className={`mobile-btn ${props.isActive ? 'show-mobile-btn' : ''}`}>
                        <span></span>
                    </div>

                </div>

            </div>

            <div onClick={props.handleToggle} className={props.isActive ? 'backdrop' : 'disappear'}></div>

        </header>
    );
};


export default HeaderUI;


