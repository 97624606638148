import React from 'react';
import Details from '../../components/Details/Details'
import DetoxProgram from "../../components/DetoxProgram/DetoxProgram";


const DetailsPage = () => {
    return (
        <>
            <Details/>
            <DetoxProgram detail/>
        </>
    );
};

export default DetailsPage;