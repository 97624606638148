import {GET_ANSWER} from "../actions/type";

const initialState = {}

const detoxAnswerReducer = (answer = initialState,action) =>{

    const {type,payload} = action

    switch(type){
        case GET_ANSWER:
            return payload
        default:
            return answer
    }
}
export default detoxAnswerReducer