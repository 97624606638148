export const GET_PROGRAM= "GET_PROGRAM"
export const GET_ANSWER= "GET_ANSWER"
export const GET_HEADER = "GET_HEADER"
export const GET_DETAILS = "GET_DETAILS"
export const MAIN_TELEGRAM = 'MAIN_TELEGRAM'
export const DETAIL_TELEGRAM = 'DETAIL_TELEGRAM'
export const CLEAR_STATE = 'CLEAR_STATE'
export const GET_VIDEO = 'GET_VIDEO'
export const GET_FOOTER = 'GET_FOOTER'
export const GET_LINKS = 'GET_LINKS'
export const CLEAR_MODAL = 'CLEAR_MODAL'