import React, {useState, useEffect} from 'react';
import './Burger.css';
import './Header.css';
import HeaderUI from "./HeaderUI/HeaderUI";
import {useSelector, useDispatch} from "react-redux";
import {getDetoxHeaderAPI, getDetoxLinksAPI} from "../../redux/actions/dal";


const Header = () => {

    const dispatch = useDispatch()
    const {detoxHeader} = useSelector(state => state)
    const {detoxLinks} = useSelector(state => state)

    useEffect(() => {
        dispatch(getDetoxHeaderAPI())
        dispatch(getDetoxLinksAPI())
    }, [dispatch])

    const [isActive, setActive] = useState(false)
    const handleToggle = () => {
        setActive(!isActive)
    }

    return <HeaderUI
        isActive={isActive}
        handleToggle={handleToggle}
        detoxHeader={detoxHeader}
        detoxLinks={detoxLinks}
    />
};

export default Header;