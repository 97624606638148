import React from 'react';
import './ErrorPage.css'
import errorIcon from '../../assets/images/errorIcon.svg'
import {Link} from 'react-router-dom'

const ErrorPage = () => {
    return (
        <section id={'error'}>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="error__box">
                            <img src={errorIcon} alt="error"/>
                            <Link to={'/'}><button>Перейти на главную страницу</button></Link>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="error__box">
                            <p className="error__text">404</p>
                            <p className="error__hint">Страница не найдена</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ErrorPage;