import React, {useState,useEffect} from 'react';
import consultImg from '../../assets/images/consultation.jpeg'
import './Consultation.css'
import {useSelector} from "react-redux";
import Confirm from "../Confirm/Confirm";
import TelegramMain from "../TelegramMain/TelegramMain";
import {useDispatch} from "react-redux";



const Consultation = () => {

    const dispatch = useDispatch()
    const {mainTelegram} = useSelector(state=>state)
    const [state,setState] = useState(false)


    useEffect(()=>{
            if(mainTelegram===200){
                setState(true)
            }

    },[mainTelegram,dispatch])


    return (
        <>
            <section className={'consultation'}>
                <div className={'container consultation__container'}>
                    <div className={'row consultation__row'}>
                        <div className={'col-4'}>
                            <div className={'consultation__box'}>
                                <img className={'consultation__img'} src={consultImg} alt="consultation-img"/>
                            </div>
                        </div>

                        <div className={'col-8'}>
                            <TelegramMain mainTelegram={mainTelegram}/>
                        </div>
                    </div>
                </div>
            </section>
            {state?<Confirm setState={setState}/>:null}
        </>

    );
};

export default Consultation;