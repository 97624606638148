import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {imgAPI} from "../../../config";
import {Link} from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css'
import {LinearProgress} from "@mui/material";

const DetoxMainSlider = ({detoxProgram, scrollToTop, bubble, detail, loading}) => {


    return (
        <>
            <Swiper
                slidesPerView={4}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    820: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                    1300: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                className="mySwiper"
            >
                {loading && <LinearProgress color="success"/>}
                {detoxProgram && detoxProgram.map((el, index) => {
                    const imgStyle = {
                        backgroundImage:`url(${imgAPI}${el.image}),linear-gradient(to right, #a8ff78, #78ffd6)`,
                        width: '100%',
                        height:'166px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom'
                    }
                    return (
                        <SwiperSlide key={index}>
                            <Link onClick={detail ? scrollToTop : bubble} to={'/details/' + el.id}>
                                <div className="detoxProgram__box">
                                    {/*<img src={imgAPI + el.image}/>*/}
                                    <div className={'detoxProgram__img'} style={imgStyle}></div>

                                    <div className="detoxProgram__box-items">
                                        <p>{el.title}</p>
                                        <p>{el.description}</p>
                                        <p>
                                            {(parseInt(el.price).toFixed(0)).toString() + ' сом'}
                                        </p>
                                    </div>

                                    <button className={'detoxProgram__button'}>Подробнее</button>

                                </div>
                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    );
};

export default DetoxMainSlider;