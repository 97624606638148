import React, {useEffect,useState} from 'react';
import './DetoxProgram.css'
import {useDispatch, useSelector} from "react-redux";
import {getDetoxProgramAPI} from "../../redux/actions/dal";
import DetoxMainSlider from "./DetoxMainSlider/DetoxMainSlider";



const DetoxProgram = ({detail}) => {

    const dispatch = useDispatch()
    const {detoxProgram} = useSelector(state => state)
    const [loading, setLoading] = useState(true)



    const scrollToTop = (e) =>{
        e.stopPropagation()
        window.scrollTo(0, 0);

    }

    const bubble = (e) =>{
        e.stopPropagation()
    }

    useEffect(() => {
        dispatch(getDetoxProgramAPI(setLoading))
    }, [dispatch])



    return (

        <section id={'detoxProgram'} className={'detox__program-bg'}>

            <div className="container">
                {detail?<h2 className='detoxProgram__head'>Вам также может понравиться</h2>:<h2>Детокс-программы:</h2>}
            </div>

        <DetoxMainSlider
            detoxProgram={detoxProgram}
            scrollToTop={scrollToTop}
            bubble={bubble}
            detail={detail}
            loading={loading}
        />
        </section>
    );
};

export default DetoxProgram;