import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom'
import MainPage from "./pages/MainPage/MainPage";
import './Main.css'
import DetailsPage from "./pages/DetailsPage/DetailsPage";
import ErrorPage from "./components/ErrorPage/ErrorPage";


const Main = () => {
    return (
        <Routes>
            <Route path={'/'} element={<MainPage/>}/>
            <Route path={'/details/:id'} element={<DetailsPage/>}/>
            <Route path={'/errorPage'} element={<ErrorPage/>}/>
            <Route
                path="*"
                element={<Navigate to='/errorPage' replace/>}
            />
        </Routes>
    );
};

export default Main;