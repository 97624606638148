import React, {useState} from 'react';
import {sendTelegramMessage} from "../../redux/actions/dal";
import {useDispatch} from "react-redux";


const TelegramMain = ({mainTelegram}) => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [tel, setTel] = useState('')
    const [valid, setValid] = useState(false);
    const nameRegex = /^[а-яА-Яa-zA-Z\-]{1,30}$/


    const validatePassword = (value) => {
        if (!nameRegex.test(value)) {
            setValid(false);
        } else {
            setValid(true);
        }
    };


    const writeName = (e) => {
        setName(e.target.value)
        validatePassword(e.target.value);
    }

    const writeTel = (e) => {
        setTel(e.target.value)
    }

    const sendMessage = () => {
        dispatch(sendTelegramMessage(data))
        setName('')
        setTel('')
    }

    const data = `Имя: ${name}  Телефон: ${tel}`

    const validButton = valid && tel.length >= 10 && tel.length<=20

    return (
        <div>
            <div className={'consultation__box'}>
                <h2>Оставьте заявку <br/> для бесплатной консультации</h2>
                <p className={'consultation__program'}>Наш штатный нутрициолог поможет Вам подобрать детокс-программу,
                    которая приведёт точно к цели.</p>

                <div className={'consultation__data'}>

                    <div className={'consultation__enter'}>
                        <label
                            htmlFor={'consultation__target-text'}
                            className={name.length === 0 ? 'consultation__label' : name.length < 3 || (!nameRegex.test(name) && name.length > 2) ? 'consultation__error' : 'consultation__label'}>
                            {name.length === 0
                                ? 'Ваше имя'
                                : name.length < 3 && !nameRegex.test(name)
                                    ? 'Недостаточно букв и числа,символы,пробелы недопустимы'
                                    : name.length < 3
                                        ? 'Недостаточно букв'
                                        : !nameRegex.test(name)
                                            ? 'Числа,символы,пробелы недопустимы'
                                            : nameRegex.test(name)
                                                ? 'Корректно'
                                                : 'Ваше имя'}
                        </label>
                        <input
                            id={'consultation__target-text'}
                            maxLength={30}
                            value={name}
                            className={'consultation__input'}
                            type="text"
                            placeholder='Введите ваше имя'
                            onChange={writeName}
                        />
                    </div>

                    <div className={'consultation__enter'}>
                        <label
                            htmlFor={'consultation__target-number'}
                            className={tel.length === 0
                                ? 'consultation__label'
                                : tel.length < 10 || tel.length > 20
                                    ? 'consultation__error' : 'consultation__label'}
                        >
                            {tel.length === 0
                                ? 'Номер телефона'
                                : tel.length < 10
                                    ? 'Введите полный номер'
                                    : tel.length > 20
                                        ? 'Слишком длинный номер'
                                        : 'Корректно'}
                        </label>
                        <input
                            id={'consultation__target-number'}
                            value={tel}
                            className={'consultation__input'}
                            type="number"
                            placeholder='Введите номер'
                            onChange={writeTel}
                        />
                    </div>

                    <div className={'consultation__enter'}>
                        {mainTelegram?.code === 'ERR_NETWORK' ?
                            <label className={'consultation__errNetwork'}>Проверьте подключение к
                                интернету</label> : null}
                        <button
                            id={'submit'}
                            className={validButton? 'consultation__button' : 'consultation__button consultation__disabled'}
                            onClick={sendMessage}
                            disabled={!validButton}
                        >Отправить
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TelegramMain;