import React, {useState} from 'react';
import './TelegramForm.css'
import {detailTelegramMessage} from "../../redux/actions/dal";
import {useDispatch} from "react-redux";

const TelegramForm = ({title, detailTelegram}) => {
    const dispatch = useDispatch()
    const [count, setCount] = useState(1)
    const [tel, setTel] = useState('')

    const countPlus = () => {
        if (count !== 7) {
            setCount(count + 1)
        }
    }

    const countMinus = () => {
        if (count !== 1) {
            setCount(count - 1)
        }
    }

    const sendTel = (e) => {
        setTel(e.target.value)
    }

    const sendMessage = () => {
        dispatch(detailTelegramMessage(data))
        setTel('')
        setCount(1)
    }

    const data =
        `Продукт:${title}
         кол-во: ${count}  
         Телефон: ${tel}
         `

    const validButton = tel.length < 10 || tel.length > 20

    return (
        <div className="telegram">
            <div className={'telegram__wrap'}>
                <div className={'telegram__wrap-input'}>
                    <div className="telegram__box">
                        <label>Кол-во</label>
                        <div className="telegram__count">
                            <p onClick={countMinus} className="telegram__minus">-</p>
                            <p style={count === 7 ? {color: '#5AAA65'} : null} className="telegram__counter">{count}</p>
                            <p onClick={countPlus} className="telegram__plus">+</p>
                        </div>
                    </div>
                    <div className="telegram__box telegram__box-2">
                        <label
                            htmlFor='telegram__target'
                            className={tel.length === 0
                                ? null
                                : tel.length < 10 || tel.length > 20
                                    ? 'telegram__red' : null}
                        >
                            {tel.length === 0
                                ? 'Номер телефона'
                                : tel.length < 10 ? 'Введите полный номер'
                                    : tel.length > 20
                                        ? 'Слишком длинный номер'
                                        : 'Корректно'}
                        </label>
                        <input
                            id='telegram__target'
                            max='14'
                            type="number"
                            placeholder='Введите номер'
                            value={tel}
                            onChange={sendTel}
                        />
                    </div>
                </div>

                <button
                    className={validButton? 'telegram__disabled' : null}
                    onClick={sendMessage}
                    disabled={validButton}
                >Оформить заказ
                </button>
                {detailTelegram?.code === 'ERR_NETWORK' ?
                    <label className={'telegram__errNetwork'}>Проверьте подключение к интернету</label> : null}
            </div>

        </div>
    );
};

export default TelegramForm;