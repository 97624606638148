import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import './AnswerAccordion.css'


const AnswerAccordion = ({detoxAnswer}) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataOne?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <p>{detoxAnswer.dataOne?.first}</p>

                        <div className="answer__details-noGap answer__dots">
                            <p>{detoxAnswer.dataOne?.second}</p>
                                {detoxAnswer.dataOne?.array.map((el, id) => {
                                    return (
                                        <p key={id}>{el}</p>
                                    )
                                })}
                        </div>
                        <p>{detoxAnswer.dataOne?.third}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataTwo?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <p>{detoxAnswer.dataTwo?.first}</p>
                        <div className="answer__details-child">
                                {detoxAnswer.dataTwo?.array.map((el, id) => {
                                    return (
                                        <div key={id}>
                                            <p>{el.title}</p>
                                            <p>{el.description}</p>
                                        </div>
                                    )
                                })}
                        </div>
                        <p>{detoxAnswer.dataTwo?.second}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataThree?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <div className="answer__details-child">
                                {detoxAnswer.dataThree?.array.map((el, id) => {
                                    return (
                                        <div key={id}>
                                            <p>{el.title}</p>
                                            <p>{el.description}</p>
                                        </div>
                                    )
                                })}
                        </div>
                        <p>{detoxAnswer.dataThree?.first}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataFour?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <p>{detoxAnswer.dataFour?.first}</p>
                        <p>{detoxAnswer.dataFour?.second}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataFive?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <div className="answer__details-noGap answer__dots">
                                <p>{detoxAnswer.dataFive?.first}</p>
                                {detoxAnswer.dataFive?.array.map((el, id) => {
                                    return (

                                            <p key={id}>{el}</p>

                                    )
                                })}
                        </div>
                        <p>{detoxAnswer.dataFive?.second}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataSix?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <p>{detoxAnswer.dataSix?.first}</p>
                        <div className="answer__details-noGap answer__dots">
                                <p>{detoxAnswer.dataSix?.second}</p>
                                {detoxAnswer.dataSix?.array.map((el, id) => {
                                    return (
                                            <p key={id}>{el}</p>
                                    )
                                })}
                            </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataSeventh?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        {detoxAnswer.dataSeventh?.array.map((el, id) => {
                            return (
                                <div key={id}>
                                    <p>{el}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataEight?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        {detoxAnswer.dataEight?.array.map((el, id) => {
                            return (
                                <div key={id}>
                                    <p>{el}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>


            <Accordion
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataNine?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <p>{detoxAnswer.dataNine?.first}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataTen?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <p>{detoxAnswer.dataTen?.first}</p>
                        <p>{detoxAnswer.dataTen?.second}</p>
                        <div className="answer__details-noGap answer__dots">
                                <p>{detoxAnswer.dataTen?.third}</p>
                                {detoxAnswer.dataTen?.array.map((el, id) => {
                                    return (
                                            <p key={id}>{el}</p>
                                    )
                                })}
                            </div>
                        <p>{detoxAnswer.dataTen?.fourth}</p>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataEleven?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        {detoxAnswer.dataEleven?.array.map((el, id) => {
                            return (
                                <div key={id}>
                                    <p>{el}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === 'panel12'}
                onChange={handleChange('panel12')}
            >
                <AccordionSummary
                    expandIcon={<AddIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{detoxAnswer.dataTwelve?.question}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className="answer__details">
                        <div className="answer__details-noGap answer__dots">
                            <p className='answer__bank'>{detoxAnswer.dataTwelve?.first}</p>
                            {detoxAnswer.dataTwelve?.array.map((el, id) => {
                                return (
                                        <p key={id}>{el}</p>
                                )
                            })}
                        </div>
                        <p>{detoxAnswer.dataTwelve?.second}</p>
                        <p>{detoxAnswer.dataTwelve?.third}</p>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default AnswerAccordion;