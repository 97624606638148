import React, {useEffect, useState} from 'react';
import lineFooter from '../../assets/images/LineFooter.svg'
import logoWA from '../../assets/images/logoWA.png'
import logoInst from '../../assets/images/logoInst.png'
import logoTelegram from '../../assets/images/telegramIcon.png'
import './Footer.css'
import {useSelector, useDispatch} from "react-redux";
import {getDetoxFooterAPI} from "../../redux/actions/dal";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const Footer = () => {

    const dispatch = useDispatch()
    const detoxFooter = useSelector(state => state.detoxFooter)


    useEffect(() => {
        dispatch(getDetoxFooterAPI())
    }, [dispatch])

    return (
        <section className={'footer'}>
            <div className={'container footer__container'}>
                <div className={'footer__left'}>
                    <div className="footer__left-wrap">
                        <img
                            onError={(event) => event.target.style.display = 'none'}
                            id={'logoFooter'} src={detoxFooter?.logo}
                            alt={''}
                        />

                        <img id={'lineFooter'} src={lineFooter} alt="footer"/>
                        <div className={'footer__switch'}>
                                <img
                                    onError={(event) => event.target.style.display = 'none'}
                                    id={'qrFooter'}
                                    src={detoxFooter?.qr}
                                    alt={''}
                                />
                            <div className={'footer__info'}>
                                <p className={'footer__title'}>Green Market</p>
                                <p className={'footer__p'}>Посетите нашу <br/> Инстаграм страницу</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'footer__right'}>
                    <p id={'footer__title'} className={'footer__title'}>{detoxFooter?.number1 ||
                        <Skeleton width={200}/>}</p>
                    <p id={'footer__title'} className={'footer__title'}>{detoxFooter?.number2 ||
                        <Skeleton width={200}/>}</p>

                    <div className={'footer__address'}>
                        <p className={'footer__p'}>Наш адрес:</p>
                        <p className={'footer__p'}>{detoxFooter?.address || <Skeleton/>}</p>
                    </div>
                    <div className={'footer__address'}>
                        <p className={'footer__p'}>Наша почта:</p>
                        <p className={'footer__p'}>{detoxFooter?.email || <Skeleton/>}</p>
                    </div>

                    <div className={'footer__social'}>

                        <a target="_blank" rel={'noreferrer'}
                           href={`https://t.me/${detoxFooter?.telegram?.split(' ').join('')}`}>
                            <img className={'footer__link'} src={logoTelegram} alt=""/>
                        </a>
                        <a target="_blank" rel={'noreferrer'}
                           href={`https://wa.me/${detoxFooter?.whatsapp?.split(' ').join('')}`}>
                            <img className={'footer__link'} src={logoWA} alt=""/>
                        </a>
                        <a target="_blank" rel={'noreferrer'} href={detoxFooter?.instagram}>
                            <img className={'footer__link'} src={logoInst} alt=""/>
                        </a>
                    </div>
                    <p className={'footer__company'}>Разработчик: ITCSoft</p>
                </div>

            </div>
        </section>
    );
};

export default Footer;