import React from 'react';
import './Cover.css';
import {Link} from "react-scroll";


const Cover = () => {
    return (
        <section id={'cover'}>
            <div className="container cover__container">
                <div className="cover__wrap">
                    <h1>Скажи токсинам <br/>
                        и шлакам – Нет!</h1>
                    <p>Откройте для себя новую жизнь <br/>
                        полную энергии и бодрости <br/>
                        с детокс программой от ГринМаркет</p>
                    <Link to={'detoxProgram'} smooth={true}><button>Смотреть продукцию</button></Link>
                </div>
            </div>
        </section>
    );
};

export default Cover;