import React, {useEffect} from 'react';
import './Confirm.css'
import {clearModal} from "../../redux/actions/dal";
import {useDispatch} from "react-redux";
import confirmForm from '../../assets/images/completeform.svg'

const Confirm = ({setState}) => {

    const dispatch = useDispatch()
    const changeState = () => {
        setState(false)
    }

    useEffect(() => {
        return () => {
            dispatch(clearModal())
        }
    }, [dispatch])

    useEffect(() => {
        setTimeout(()=>setState(false),3500)
    }, [setState])

    useEffect(() => {
         document.body.style.overflow = 'hidden'
        return () =>{
            document.body.style.overflow = 'unset'
        }
    }, [])

    return (
        <section>
            <div onClick={changeState} className="confirm__backdrop">
                <div onClick={e=> e.stopPropagation()} className="modal">
                    <img src={confirmForm} alt="icon"/>
                    <h2>Отправлено!</h2>
                    <p>Спасибо за Вашу заявку,<br/>
                        мы скоро с вами свяжемся.</p>
                    <button onClick={changeState}>Закрыть</button>
                </div>
            </div>
        </section>
    );
};

export default Confirm;