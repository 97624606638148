import React,{useEffect} from 'react';
import './Answer.css'
import {getDetoxAnswerAPI} from "../../redux/actions/dal";
import {useSelector,useDispatch} from "react-redux";
import AnswerAccordion from "./AnswerAccordion/AnswerAccordion";

const Answer = () => {


    const dispatch = useDispatch()
    const {detoxAnswer} = useSelector(state=>state)


    useEffect(()=>{
        dispatch(getDetoxAnswerAPI())
    },[dispatch])

    return (
        <section id={'answer'}>
            <div className="container">
                <h2>Ответы на вопросы:</h2>
                <div className={'answer__wrap'}>
                    <AnswerAccordion
                        detoxAnswer={detoxAnswer}
                    />
                </div>
            </div>
        </section>
    );
};

export default Answer;