import React,{useEffect,useState}  from 'react';
import './Feedback.css'
import {useDispatch, useSelector} from "react-redux";
import { getDetoxVideoAPI} from "../../redux/actions/dal";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import videoPlayer from '../../assets/images/Media-Video-Player.png'
import {LinearProgress} from "@mui/material";



const Feedback = () => {

    const dispatch = useDispatch()
    const {detoxVideo} = useSelector(state => state)
    const [loading, setLoading] = useState(true)

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    function enterDiv(e){
        e.target.play()
    }

    function leaveDiv(e) {
        e.target.pause()
    }


    useEffect(() => {
        dispatch(getDetoxVideoAPI(setLoading))
    }, [dispatch])


    return (
        <section id={'feedback'}>
            <div className="container">
                <h2>Отзывы:</h2>
            </div>

            <Swiper
                slidesPerView={4}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    820: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                    1300: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                className="mySwiper"
            >

                {loading && <LinearProgress color="success"/>}
                {detoxVideo && detoxVideo.map((el, index) => {
                    return (
                        <SwiperSlide key={index}>

                            {el.video
                                ?  <div
                                    className="video__box"
                                    id="content"
                                >
                                    <video
                                        autoPlay={/android/i.test(userAgent) || /windows phone/i.test(userAgent) || /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)}
                                        loop
                                        muted
                                        name="media"
                                        playsInline
                                        onMouseEnter={enterDiv}
                                        onMouseLeave={leaveDiv}
                                    >
                                        <source
                                            src={el.video}
                                            type="video/mp4"/>
                                    </video>
                                </div>
                                :<div
                                    className="video__box"
                                >
                                    <img src={el.image} alt="Feedback img"/>
                                </div>
                            }
                            <img className={'feedback__play-btn'} src={videoPlayer} alt="player"/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </section>
    );
};

export default Feedback;