import {GET_LINKS} from "../actions/type";

const initialState = []

const detoxProgramReducer = (data = initialState,action) =>{

    const {type,payload} = action

    switch(type){
        case GET_LINKS:
            return payload
        default:
            return data
    }
}
export default detoxProgramReducer