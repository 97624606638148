
export const API = 'https://back.detox.today/api/v1'

export const imgAPI = 'https://back.detox.today/'

export const dataLinks = `${API}/links/`

export const dataFooter = `${API}/footers/`

export const dataProgram = `${API}/products/`


export const dataAnswer = {
    dataOne: {
        question: 'Что такое детокс?',
        first: 'Детокс – это мягкий и комфортный метод очищения организма от токсинов и шлаков. Он построен на смене системы питания с упором на нормализацию обмена веществ и восстановление пищеварительной системы.',
        second: 'За счёт чего в организме копятся токсины:',
        array: [
            'рафинированные и консервированные продукты,',
            'красители, ',
            'антибиотики, ',
            'кофеин, алкоголь, ',
            'грязный воздух, хлорированная вода, ',
            'сидячий образ жизни, стресс.'
        ],
        third: 'Задача детокс-программы в том, чтобы дать вашему организму возможность направить ресурсы на процессы очищения и восстановления. Чем чище организм, его органы и ткани — тем больше здоровья, энергии и радости Вы ощущаете.'
    },
    dataTwo: {
        question: 'Как понять, что организм зашлакован?',
        first: 'Перечислим самые основные признаки.',
        array: [
            {
                title:'1. Упадок сил',
                description:'Если пробуждение по утрам даётся тяжело, а сон при этом полноценный и жалоб на здоровье нет, то скорее всего, организм зашлакован.'
            },
            {
                title:'2. Частые головные боли',
                description:'Головные боли без особых причин могут свидетельствовать о том, что организм не справляется с выводом продуктов жизнедеятельности. Зашлакованная кровь менее питательна и более густая, это затрудняет ее движение по сосудам, вызывая головные боли.',
            },
            {
                title:'3. Налет на языке',
                description:'Это указывает на нарушения в работе пищеварительной системы из-за накопления шлаков или наличие инфекционного заболевания.'
            },
            {
                title:'4. Жировые отложения в области талии',
                description: 'Лишний вес — это признак сбоя систем организма. У людей с замедленным обменом веществ шлаки выводятся хуже. Скопившиеся токсины еще больше замедляют метаболизм, вследствие чего увеличивается вес.'
            },
            {
                title:'5. Проблемы с кожей',
                description:'Различные покраснения, шелушения, сыпь – явный признак проблем с выведением отходов. Напрасно лечить их наружными средствами, когда корень вопроса кроется глубже.'
            },
            {
                title:'6. Повышенная потливость.',
                description: 'С потом из организма выходят аммиак, мочевина и другие остатки жизнедеятельности. Когда в организме избыток токсинов, повышается потоотделение. '
            }
        ],
        second: 'Наличие всех или хотя бы одного симптома указывает на необходимость срочной очистки тела от шлаков.'
    },
    dataThree:{
        question:'Результаты от 1-3-5-7-дневных программ?',
        array:[
            {
                title:'1-дневный детокс идеален для мягкого очищения и станет свежим глотком воздуха для организма, если Вы ещё не готовы к более глубокому преображению.',
                description:'Новичкам рекомендуется начинать с 1-3 дней детокса, чтобы почувствовать свой организм и прислушаться к ощущениям.'
            },
            {
                title:'3-дневная детокс-диета подходит тем, кто хочет легко разгрузить и очистить тело.',
                description:'За 3 дня программы из организма выводятся шлаки и токсины, ускоряется метаболизм, все системы насыщаются витаминами и другими нутриентами.'
            },
            {
                title:'5-дневная программа запускает обновление. ',
                description: 'Тело не только очищается и насыщается, но и омолаживается: кожа разглаживается, выравнивается ее тон, укрепляются ногти и волосы.'
            },
            {
                title:'7-дневная детоксикация полностью очищает тело и мысли. ',
                description: 'Помимо эффекта от 3- и 5-дневных программ, 7-дневный детокс мощно сжигает лишние килограммы и обеспечивает ясность мыслей.'
            },
        ],
        first:'Продолжительность диеты выбирается на бесплатной консультации, исходя из возможностей организма и ваших пожеланий. '
    },
    dataFour:{
        question:' Если есть аллергия на некоторые компоненты?',
        first:'У нас доступен индивидуальный подбор ингредиентов на случай, если у вас имеется аллергия на какой-либо продукт или особые пожелания к детокс-программе.',
        second:'Перед заказом диеты проконсультируйтесь с врачом, а затем мы вместе подберем вам подходящий состав.'
    },
    dataFive:{
        question:'Кому не рекомендуется проходить детокс?',
        first:'К сожалению, среди тех, кому можно проходить детокс, имеются исключения. Это: ',
        array:[
            'лица, не достигшие совершеннолетнего возраста;',
            'люди, болеющие сахарным диабетом;',
            'люди, имеющие проблемы с ЖКТ (язва желудка, тяжелая форма гастрита).'
        ],
        second:'Если у вас возникли дополнительные вопросы о том, стоит ли вам пробовать диету, то наш штатный нутрициолог готов проконсультировать вас.'
    },
    dataSix:{
        question:'Программы для беременных и кормящих',
        first:'Такая программа более мягкая и используется как дополнение к ежедневному рациону.',
        second:'Программа полезна следующим:',
        array:[
            'утоляет голод при токсикозе;',
            'борется с тошнотой;',
            'является профилактикой отеков и прибавки веса;',
            'предупреждает дефицит йода и анемию;',
            'укрепляет иммунитет;',
            'успокаивает или, наоборот, бодрит;',
             'не вызывает колик и метеоризма;'   ,
            'абсорбирует токсины.'
        ]
    },
    dataSeventh:{
        question:'Подходит ли детокс-программа мужчинам?',
        array:[
            'Подходит на все сто.',
            'Пищеварительная система мужчины и женщины практически не отличаются. ',
            'Весь мужской пол, который ведет активный образ жизни и придерживается здорового питания,но или планирует может спокойно проходить детокс-программу. '
        ]
    },
    dataEight:{
        question:'Не будет ли слабительного эффекта?',
        array:[
            'Очищение детокс-программами проходит щадящим образом без особого изменения обычного дня. В состав продуктов входят натуральные овощи и фрукты, которые не оказывают негативного влияния на организм. ',
            'Отличие в том, что  они переработаны и измельчены и легче усваиваются желудком.',
            'При этом в организм поступает нужное количество витаминов, макро и микроэлементов, клетчатки, фруктозы и пектина.'
        ]
    },
    dataNine:{
        question:'Тренировки во время детокс-программы',
        first:'Конечно! Замена обычной пищи соками и смузи никак не отразится на занятиях спортом. Новички могут почувствовать временную слабость с непривычки, но это не критично. Просто замените интенсивную тренировку в детокс-день на более легкую и размеренную. Например, хорошо подойдут занятия йогой, плавание в бассейне или медитация.'

    },
    dataTen:{
        question:'Подготовка к прохождению детокса',
        first:'Для того, чтобы диета прошла легче и результативнее, к ней нужно подготовиться.',
        second:'Подготовка совсем простая!',
        third:'Она начинается за 1 день до программы и состоит из следующих шагов: ',
        array:[
            'Поужинайте не позднее, чем за 4 часа до сна.',
            'Минимизируйте употребление кофе и чая.',
            'Воздержитесь от алкоголя, мучного, сладкого, красного мяса и от молочной продукции.'
        ],
        fourth:'Также важный фактор – моральная подготовка. Настройтесь на мощный результат и не отклоняйтесь от пути.'
    },
    dataEleven:{
        question:'Как правильно выйти из детокса',
        array:[
            'Выход из детокс-диеты должен быть плавным.',
            'После окончания детокс-диеты постепенно добавляйте в рацион нежирные белковые продукты, небольшое количество соли и приправ, затем увеличивайте количество углеводной пищи и плавно возвращайтесь к привычному рациону. ',
            'Пейте больше воды и отдавайте предпочтение сырым овощам и фруктам'
        ]
    },
    dataTwelve:{
        question:'Оплата и доставка',
        first:'Для вашего удобства у нас гибкий метод оплаты. Вы можете произвести расчёт посредством:',
        array:[
            'MBank,',
            'Optima24',
            'DemirBank,',
            'Элсом ',
            'Оплаты курьеру наличными.'
        ],
        second:'Доставка проводится каждый вечер с 19:00 до 22:30 в любое удобное для вас место. ',
        third:'Время связано с тем, что в течение дня мы занимаемся приготовлением программ, а вечером максимально свежими привозим их к вам. '
    },
}

export const dataVideo =   `${API}/video/`

export const dataHeader = {
    consultation: 'Перезвоните мне',
    navData: [
        {cover: 'Главная'},
        {advantage: 'Преимущества'},
        {detoxProgram: 'Продукция'},
        {consultation: 'Доставка и оплата'},
        {feedback: 'Отзывы'},
    ],
}


const botMain = {
    TOKEN:'5306157333:AAFWd9oU9SzTSV6a-chCRyQO_TQpUcTRjDI',
    chatID:'-1001276453094'
}

export const dataTelegram = `https://api.telegram.org/bot${botMain.TOKEN}/sendMessage?chat_id=${botMain.chatID}&text=`

const botDetail = {
    TOKEN:'5585891940:AAG_hPWTTbJhE9wMMW1tOheXsZaxBdmmoNM',
    chatID:'-1001758328807'
}

export const detailTelegram = `https://api.telegram.org/bot${botDetail.TOKEN}/sendMessage?chat_id=${botDetail.chatID}&text=`


