import {GET_VIDEO} from "../actions/type";

const initialState = []

const detoxVideoReducer = (video = initialState,action) =>{

    const {type,payload} = action

    switch(type){
        case GET_VIDEO:
            return payload
        default:
            return video
    }
}
export default detoxVideoReducer