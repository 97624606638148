import {MAIN_TELEGRAM,CLEAR_MODAL} from "../actions/type";

const initialState = ''

const detoxTelegramReducer = (message = initialState,action) =>{

    const {type,payload} = action

    switch(type){
        case MAIN_TELEGRAM:
            return payload
        case CLEAR_MODAL:
            return payload
        default:
            return message
    }
}
export default detoxTelegramReducer


